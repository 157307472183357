<!-- 系统日志查询模块 -->
<template>
  <div>
    <div class="tool">
      <a-input-search
        v-model:value="searchParam.searchMessage"
        :disabled="searchDisabled"
        placeholder="请输入关键词"
        enter-button
        @search="onSearch"
      />
      <a-button
        type="primary"
        @click="onAdvanceSearch"
        style="margin: 0px 10px 0px 10px"
      >
        <SvgIcon iconName="search" className="svg16" />高级搜索
      </a-button>
    </div>
    <div v-show="showSearchArea" class="searchAreaCss">
      <a-form :model="searchParam">
        <a-row>
          <a-col :span="4">
            <a-form-item
              label="用户:"
              name="searchUsername"
              :labelCol="formLayout2X2.labelCol"
              :wrapperCol="formLayout2X2.wrapperCol"
            >
              <a-input
                v-model:value="searchParam.searchUsername"
                type="text"
                placeholder="请输入用户名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              label="状态码:"
              name="searchStateCode"
              :labelCol="formLayout2X2.labelCol"
              :wrapperCol="formLayout2X2.wrapperCol"
            >
              <a-input
                v-model:value="searchParam.searchStateCode"
                type="text"
                placeholder="请输入状态码"
              />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              label="类型:"
              name="searchLogType"
              :labelCol="formLayout2X2.labelCol"
              :wrapperCol="formLayout2X2.wrapperCol"
            >
              <a-select
                v-model:value="searchParam.searchLogType"
                placeholder="请选择日志类型"
              >
                <a-select-option
                  v-for="(item, index) in logTypeList"
                  :key="index"
                  :value="item.value"
                  >{{ item.item }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-row>
              <a-col :span="18">
                <a-form-item
                  label="创建时间:"
                  name="searchCreateDate"
                  :labelCol="formLayout2X2.labelCol"
                  :wrapperCol="formLayout2X2.wrapperCol"
                >
                  <a-range-picker
                    v-model:value="searchParam.searchCreateDate"
                    :locale="locale"
                    :format="dateFormat"
                    @change="onRangePickerOK"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="5" :offset="1">
                <a-button type="primary" @click="doSearch"
                  ><SearchOutlined />开始搜索</a-button
                >
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="systemLogList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #action="{ record }">
          <span>
            <a-button
              type="link"
              size="small"
              @click="doDetailDictionary(record)"
            >
              <FileTextOutlined />详情
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div>
      <a-modal
        title="详情"
        width="60%"
        v-model:visible="modalVisible"
        footer=""
      >
        <div class="desc">
          <a-descriptions title="日志详细信息" bordered>
            <a-descriptions-item label="用户名">
              {{ dictionaryInfo.username }}
            </a-descriptions-item>
            <a-descriptions-item label="日志类型">
              {{ dictionaryInfo.dictionaryLogType.item }}
            </a-descriptions-item>
            <a-descriptions-item label="状态码">
              {{ dictionaryInfo.stateCode }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ $filter.formatDate(dictionaryInfo.createDate) }}
            </a-descriptions-item>
            <a-descriptions-item label="最后登录时间" :span="2">
              {{ dictionaryInfo.modifyDate }}
            </a-descriptions-item>
            <a-descriptions-item label="日志信息">
              {{ dictionaryInfo.message }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { FileTextOutlined, SearchOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { getSystemLogListPage } from "@/api/system/systemLog";
import { getDictionaryByCode } from "@/api/system/dictionary";
import { reactive, toRefs, defineComponent, onMounted } from "vue";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "SystemLog",
  components: {
    FileTextOutlined,
    SearchOutlined,
    SvgIcon,
  },
  setup() {
    const state = reactive({
      loading: false,
      modalVisible: false,
      showSearchArea: false,
      searchDisabled: false,
      searchParam: {
        searchMessage: null,
        searchUsername: null,
        searchStateCode: null,
        searchLogType: null,
        searchCreateDate: null,
        pageIndex: 1,
        pageSize: 10,
      },
      systemLogList: [],
      logTypeList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
      formLayout2X2: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      },
      dateFormat: "YYYY-MM-DD",
      dictionaryInfo: {},
    });
    const columns = [
      {
        title: "用户",
        dataIndex: "username",
        key: "username",
        width: "10%",
      },
      {
        title: "日志类型",
        dataIndex: "dictionaryLogType.item",
        key: "logType",
        width: "10%",
        filters: [
          {
            text: "系统错误日志",
            value: 1,
          },
          {
            text: "数据库错误日志",
            value: 2,
          },
          {
            text: "其它错误日志",
            value: 3,
          },
        ],
      },
      {
        title: "状态码",
        dataIndex: "stateCode",
        key: "stateCode",
        width: "10%",
      },
      {
        title: "日志信息",
        dataIndex: "message",
        key: "message",
        ellipsis: true,
        width: "40%",
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        width: "15%",
        sorter: (a, b) => {
          if (a.createDate < b.createDate) {
            return -1;
          }
          if (a.createDate > b.createDate) {
            return 1;
          }
          return 0;
        },
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        width: "15%",
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      initSystemLogList(state.searchParam);
      initLogTypeList("systemLog");
    });
    const initSystemLogList = (searchParam) => {
      state.loading = true;
      getSystemLogListPage(searchParam).then((resp) => {
        if (resp) {
          state.systemLogList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const initLogTypeList = (type) => {
      getDictionaryByCode(type).then((resp) => {
        if (resp) {
          state.logTypeList = resp;
        }
      });
    };
    const onSearch = () => {
      initSystemLogList(state.searchParam);
    };
    const onAdvanceSearch = () => {
      state.showSearchArea = !state.showSearchArea;
      if (state.showSearchArea) {
        state.searchParam.searchMessage = "";
        state.searchDisabled = true;
      } else {
        state.searchDisabled = false;
        state.searchParam = {
          searchMessage: null,
          searchUsername: null,
          searchStateCode: null,
          searchLogType: null,
          searchCreateDate: [],
          pageIndex: 1,
          pageSize: 10,
        };
      }
    };
    const doSearch = () => {
      initSystemLogList(state.searchParam);
    };
    const onRangePickerOK = (dates) => {
      //state.searchParam.searchCreateDate.clear();
      state.searchParam.searchCreateDate.push(
        moment(dates[0]).format("YYYY-MM-DD HH:mm:ss")
      );
      state.searchParam.searchCreateDate.push(
        moment(dates[1]).format("YYYY-MM-DD HH:mm:ss")
      );
    };
    const doDetailDictionary = (record) => {
      state.modalVisible = true;
      state.dictionaryInfo = record;
      console.log(state.modalVisible);
      console.log(state.dictionaryInfo);
    };
    const onChangePage = (page, pageSize) => {
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initSystemLogList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initSystemLogList(state.searchParam);
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      locale,
      initSystemLogList,
      initLogTypeList,
      onSearch,
      onAdvanceSearch,
      onChangePage,
      onShowSizeChange,
      doSearch,
      onRangePickerOK,
      doDetailDictionary,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: flex-start;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
.searchAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}
.ant-form-item {
  margin-bottom: 0px;
}
.desc {
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(212, 208, 248);
}
</style>