import { postRequest, putRequest, deleteRequest, getRequest } from '../request'

export const getDictionaryListPage = (res) => {
    return postRequest('/system/dict/dictionaries', res)
}

export const getDictItemListPage = (code) => {
    return postRequest('/system/dict/items', code)
}

export const saveDictionary = (res) => {
    if (res.id === 9999) {
        //添加字典项目
        return postRequest('/system/dict/dictionary', res);
    }
    else {
        //更新字典项目
        return putRequest('/system/dict/dictionary', res);
    }
}

export const deleteDictionary = (res) => {
    return deleteRequest('/system/dict/dictionary/' + res.id + "?logic=" + res.logic)
}

export const saveDictionaryItem = (res) => {
    if (res.id === 9999) {
        //添加字典项目属性
        return postRequest('/system/dict/item', res);
    }
    else {
        //更新字典项目属性
        return putRequest('/system/dict/item', res);
    }
}

export const deleteDictionaryItem = (res) => {
    return deleteRequest('/system/dict/item/' + res.id + "?logic=" + res.logic)
}

export const getDictionaryByCode = (code) => {
    return getRequest('/system/dict/item/' + code);
}